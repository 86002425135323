import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';

@Component({
    selector: 'app-menu',
    template: `
        <div class="menu">
            <ul class="layout-menu">
                <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
            </ul>
        </div>
    `
})
export class AppMenuComponent implements OnInit {

    constructor(private loginService: LoginService) {
    }

    model: any[];

    async ngOnInit() {
        const temp = [];
        temp.push({ label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/home'] });
        
        const apps = await this.loginService.getUserApplications();

        for (const app of apps) {
            temp.push({ label: app.displayName, routerLink: [app.route] });
        }

        temp.push({ label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => { this.loginService.logout(); } });
        this.model = temp;
    }
}
