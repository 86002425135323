<div class="wrapper" [ngClass]="{'sidebar-inactive-l':menuInactiveDesktop, 'sidebar-active-m':menuActiveMobile,
                                'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">
    <div #mlink class="topbar clearfix" [class.impersonating]="isImpersonating">
        <div id="topbar-icons" class="animated"
            [ngClass]="{'topbar-icons-visible flipInX':topMenuActive,'flipOutX':topMenuLeaving}">
            <span class="user-options" (click)="toggleUserOptions($event, op)"></span>
        </div>
        <div class="menu-header">
            <span routerLink="home" class="home-link"></span>
            <a class="menu-link" href="#" (click)="$event.preventDefault(); appmenu.toggle($event)"><i class="pi pi-bars"></i></a>
        </div>
    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>

<p-overlayPanel class="profile-menu" #op [dismissable]="true" styleClass="user-options">
    <label>{{userLoggedIn}}</label>
    <ul>
        <li><a routerLink="home"><i class="pi pi-home"></i>Home</a></li>
        <li><a (click)="logout()"><i class="pi pi-sign-out"></i>Logout</a></li>
    </ul>
    <label>Theme</label>
    <p-selectButton [options]="themeOptions" [(ngModel)]="selectedTheme" optionLabel="icon" (onChange)="changeTheme()">
        <ng-template let-item>
            <i [class]="item.icon"></i>
        </ng-template>
    </p-selectButton>
</p-overlayPanel>

<p-overlayPanel #appmenu [appendTo]="mlink" [dismissable]="true" styleClass="">
    <div class="app-menu p-grid">
        <div class="app-card p-col-4" (click)="navigate({route: '/home'})" >
            <div class="card-back">
                <div class="card-contents">
                    <img src="{{selectedTheme?.value === 'light' ? '/assets/layout/images/icon-home-light.svg' : '/assets/layout/images/icon-home.svg'}}" />
                </div>
            </div>
            <div>Home</div>
        </div>
        <div class="app-card p-col-4" *ngFor="let application of applications" (click)="navigate(application)">
            <div class="card-back">
                <div class="card-contents">
                    <img src="/assets/layout/images/applications/{{selectedTheme.value}}/{{application.icon}}" default="/assets/layout/images/applications/{{selectedTheme.value}}/fallback.svg" />
                </div>

            </div>
            <div>{{application.displayName}}</div>
        </div>
    </div>
</p-overlayPanel>
