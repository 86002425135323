import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../services/login.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

  applicationName: string;
  applicationURL: SafeUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private santizer: DomSanitizer,
    private loginService: LoginService
  ) {

  }

  async ngOnInit() {
    const applications = await this.loginService.getUserApplications();
    this.route.params.subscribe((paramMap) => {
      this.applicationName = paramMap['applicationName'];

      const app = _.find(applications, { applicationName: this.applicationName });
      if (app) {
        if (app.iframeURL) {
          const templateURL = _.template(app.iframeURL)(
            {
              dashUrl: environment.dashUrl,
              token: this.loginService.getToken()
            }
          )
          this.applicationURL = this.santizer.bypassSecurityTrustResourceUrl(templateURL);
        } else {
          const url = `${window.location.protocol}//${window.location.hostname}/applications/${app.applicationName}/`;
          this.applicationURL = this.santizer.bypassSecurityTrustResourceUrl(url);
        }
      } else {
        this.router.navigate(['/'])
      }
    })


  }

}
