<p-toast position="top-right"></p-toast>
<div class="login-body">
    <div id="login-wrapper">
        <div class="logo-container">
        </div>
        <div id="login-container">
            <h2>Forgot Password</h2>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <input type="text" name="username" autocomplete="off" pInputText placeholder="Username" [(ngModel)]="username" (ngModelChange)="onChange()"/>
                </div>
                <div class="p-col-12">
                    <a href="#/login">Login</a>
                </div>
                <div class="p-col-12">
                    <button pButton label="Request Password Reset" [disabled]="hasUserName" (click)="requestReset()"></button>
                </div>
            </div>
        </div>  
    </div>
    <ngx-spinner></ngx-spinner>
</div>