import { Component, AfterViewInit, OnDestroy, ViewChild, Renderer2, OnInit, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { ThemeService } from './services/theme.service';
import { LoginService } from './services/login.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Application } from 'src/model/Application';
import { Router } from '@angular/router';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ],
    styleUrls: ['./app.main.component.scss']
})
export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit {

    public menuInactiveDesktop: boolean = true;

    public menuActiveMobile: boolean;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    configActive: boolean;

    configClick: boolean;

    selectedTheme: any;

    applications: Application[];

    themes = [
        { label: 'Dark', value: 'dark' },
        { label: 'Light', value: 'light' },
    ]

    userLoggedIn: string;
    isImpersonating: boolean = false;

    themeOptions: any[];
    @ViewChild('appmenu') menuElement: OverlayPanel;

    constructor(
        public renderer: Renderer2,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent,
        private themeService: ThemeService,
        private loginService: LoginService,
        private router: Router
    ) {
        this.themeOptions = [
            { icon: 'pi pi-sun', value: 'light' },
            { icon: 'pi pi-moon', value: 'dark' }
        ];
    }

    async ngOnInit() {
        this.userLoggedIn = this.loginService.tokenClaims.email;
        this.isImpersonating = this.loginService.isImpersonating;
        this.primengConfig.ripple = true;
        this.applications = await this.loginService.getUserApplications();

        this.themeService.theme.subscribe((t) => {
            this.selectedTheme = this.themeOptions.find(item => item.value === t);
        });
    }

    logout(): void {
        this.loginService.logout();
    }

    ngAfterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.isDesktop()) {
                if (!this.menuClick) {
                    this.menuActiveMobile = false;
                }

                if (!this.topMenuButtonClick) {
                    this.hideTopMenu();
                }
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.menuClick = false;
            this.topMenuButtonClick = false;
        });

    }

    toggleUserOptions(event, overlaypanel: OverlayPanel) {
        overlaypanel.toggle(event);
    }

    toggleMenu(event: Event = null) {
        this.menuClick = true;
        if (this.isDesktop()) {
            this.menuInactiveDesktop = !this.menuInactiveDesktop;
            if (this.menuInactiveDesktop) {
                this.menuActiveMobile = false;
            }
        } else {
            this.menuActiveMobile = !this.menuActiveMobile;
            if (this.menuActiveMobile) {
                this.menuInactiveDesktop = false;
            }
        }

        if (this.topMenuActive) {
            this.hideTopMenu();
        }

        if (event) {
            event.preventDefault();
        }
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }

        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 500);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    changeTheme() {
        this.themeService.setTheme(this.selectedTheme.value);
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    navigate(application: Partial<Application>){
        setTimeout(() => {
            this.menuElement.hide();
            this.router.navigate([application.route], {state: {application}});
        }, 0)

    }
}
