<div [class.filter-active]="currentFilter?.dateFilterActive" class="date-label">
    <span *ngIf="currentFilter" >{{currentFilter.filterStart | date: 'dd MMMM yyyy'}} to {{currentFilter.filterEnd | date: 'dd MMMM yyyy'}}</span>
    <span class="datepicker-button active-link clickable" (click)="toggleDatePicker()"><i class="pi pi-calendar"></i></span>
</div>
<div class="ngb-datepicker dp-hidden">
    <div class="input-group">
        <input name="datepicker"
            class="form-control hidden-input"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="displayMonths"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
            [markDisabled]="checkDisabled"
            [footerTemplate]="t2">
        <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                [class.disabled]="checkDisabled(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
        </ng-template>
        <ng-template #t2>
            <div class="datepicker-footer">
                <div class="clear-button clickable" (click)="clearFilter();">
                    Clear Filter
                </div>
                <div class="active-link clickable" [class.disabled]="!toDate || !fromDate" (click)="applyFilter()">Apply Date Filter</div>
            </div>
        </ng-template>
    </div>
</div>