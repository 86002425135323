<div class="login-body">
    <div id="login-wrapper">
        <div class="logo-container">
        </div>
        <div id="login-container" style="height: auto;">
            <form (ngSubmit)="login()" #loginForm="ngForm">
                <div class="p-grid p-fluid">
                    <div class="p-col-12">
                        <input type="text" name="username" autocomplete="off" pInputText placeholder="Username"
                            [(ngModel)]="username" />
                    </div>
                    <div class="p-col-12">
                        <input type="password" name="password" autocomplete="off" pInputText placeholder="Password"
                            [(ngModel)]="password" />
                    </div>
                    <div class="p-col-12">
                        <button pButton label="Login" type="submit"></button>
                    </div>
                    <div class="p-col-12 p-md-12" *ngIf="showUserDisabled">
                        <span style="color: #C0C0C0;">{{userDisabledMessge}}</span>
                    </div>
                    <div class="p-col-12 p-md-12">
                        <a href="#/forgotpassword">Forgot Password</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ngx-spinner></ngx-spinner>
</div>