import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  theme: BehaviorSubject<string>;

  constructor() {
    const userStoredTheme = localStorage.getItem('theme') || 'dark'
    this.theme = new BehaviorSubject(userStoredTheme);
  }

  setTheme(theme: string) {
    localStorage.setItem('theme', theme);
    this.theme.next(theme);
  }
}
