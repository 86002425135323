import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateFilter } from 'src/app/customer/Models/dateFilter';
import { FleetFilterService } from '../fleet-filter/fleet-filter.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fleet-filter-datepicker',
  templateUrl: './fleet-filter-datepicker.component.html',
  styleUrls: ['./fleet-filter-datepicker.component.scss'],
  styles: [`
  `]
})

export class FleetFilterDatepickerComponent implements OnInit, OnDestroy {

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  @Output() dateFilterSet = new EventEmitter<DateFilter>();

  currentFilter: DateFilter;

  mobileView: boolean = false;

  @ViewChild('datepicker') datepicker: NgbInputDatepicker;

  displayMonths: number = 2;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private fleetFilterService: FleetFilterService
  ) {
    // this.fromDate = calendar.getNext(calendar.getToday(), 'd', -7);
    // this.toDate = calendar.getToday();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.checkForSavedFilter();
    });
  }

  ngOnDestroy(): void {
    this.fleetFilterService.disposeFilter()
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
      this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  checkDisabled = (date: NgbDate) => {
    if (this.fromDate && !this.toDate) {
      const lastDate = this.calendar.getNext(this.fromDate, 'd', 30);
      if (date.after(lastDate) || date.before(this.fromDate)) {
        return true;
      }
    }
    // if (date.after(this.calendar.getToday()) || date.equals(this.calendar.getToday())) {
    //   return true;
    // }
    return false;
  }

  getDisplayMonths() {
    return this.mobileView ? 1 : 2;
  }

  setDate(from: moment.Moment, to: moment.Moment) {
    if (to.isAfter(from) && to.diff(from, 'd') <= 31) {
      this.fromDate = this.momentToNgbDate(from);
      this.toDate = this.momentToNgbDate(to);
    }
  }

  applyFilter() {
    if (this.toDate && this.fromDate) {
      this.setFilterFromControls()
      this.emitFilter(this.currentFilter);
      this.datepicker.close();
    }
  }

  clearFilter() {
    this.setDefaultFilter();
    this.emitFilter(this.currentFilter);
    this.datepicker.close();
  }

  private momentToNgbDate(date: moment.Moment) {
    return NgbDate.from({
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    })
  }

  private ngbDatetoMoment(date: NgbDate) {
    return moment([
      date.year,
      date.month - 1,
      date.day
    ])
  }

  private checkForSavedFilter() {
    const savedFilterString = localStorage.getItem('date-filter');
    if (savedFilterString) {
      const filter: DateFilter = JSON.parse(savedFilterString);
      if (filter.dateFilterActive) {
        if (moment(filter.filterSetOn) < moment().startOf('d')) {
          this.setDefaultFilter();
        } else {
          this.currentFilter = filter;
          this.currentFilter.filterStart = new Date(this.currentFilter.filterStart);
          this.currentFilter.filterEnd = new Date(this.currentFilter.filterEnd);
          this.setDate(moment(this.currentFilter.filterStart), moment(this.currentFilter.filterEnd));
        }
      } else {
        this.setDefaultFilter();
      }
    } else {
      this.setDefaultFilter();
    }
    this.emitFilter(this.currentFilter);
  }

  private emitFilter(filter: DateFilter) {
    this.dateFilterSet.emit(filter);
    this.fleetFilterService.setDateFilter(filter);
  }

  private setDefaultFilter() {
    const startDate = moment().startOf('d').add(-7, 'd');
    const endDate = moment().startOf('d');
    this.setDate(startDate, endDate);
    this.storeFilter({
      dateFilterActive: false,
      filterStart: startDate.toDate(),
      filterEnd: endDate.toDate()
    });
  }

  private setFilterFromControls() {
    const startDate = this.ngbDatetoMoment(this.fromDate);
    const endDate = this.ngbDatetoMoment(this.toDate);
    this.storeFilter({
      dateFilterActive: true,
      filterSetOn: new Date(),
      filterStart: startDate.toDate(),
      filterEnd: endDate.toDate()
    })
  }

  private storeFilter(filter: DateFilter) {
    this.currentFilter = filter;
    localStorage.setItem('date-filter', JSON.stringify(this.currentFilter));
  }

  toggleDatePicker () {
    this.displayMonths = (window.outerWidth <= 512) ? 1 : 2;
    setTimeout(() => {
      this.datepicker.toggle();
    });
  }

}
