import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { ApplicationComponent } from './application/application.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password/reset-password.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivateChild: [AuthGuard],
                children: [
                    { path: 'home', component: HomeComponent },
                    { path: 'application/:applicationName', component: ApplicationComponent },
                    { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
                    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
                    { path: 'principal', loadChildren: () => import('./principal/principal.module').then(m => m.PrincipalModule) },
                    { path: 'access', component: AppAccessdeniedComponent },
                    { path: '', redirectTo: 'home', pathMatch: 'full' },
                ]
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'login', component: AppLoginComponent },
            { path: 'forgotpassword', component: ForgotPasswordComponent },
            { path: 'resetpassword', component: ResetPasswordComponent }
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
