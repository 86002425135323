import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Application } from 'src/model/Application';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  applications: Application[];
  searchText: string;
  selectedTheme: string;
  logoId: string;
  logoPath: string;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private themeService: ThemeService
  ) { }

  async ngOnInit() {
    this.applications = await this.loginService.getUserApplications();
    this.logoId = this.loginService.tokenClaims.logoId;
    this.logoPath = this.loginService.tokenClaims.logoPath;

    this.themeService.theme.subscribe((t) => {
      this.selectedTheme = t;
  });
  }

  getLogoPath(){
    if(this.logoId === "0"){
      return `/assets/layout/images/logos/${this.selectedTheme}/ice.png`;
    }else {
      return `/assets/layout/images/logos/${this.logoId}/${this.selectedTheme}/${this.logoPath}`;
    }
  }

  navigate(application: Application){
    this.router.navigate([application.route], {state: {application}});
  }
}
