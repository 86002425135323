<div class="center-align">
    <img class="logo" src="{{getLogoPath()}}" default="/assets/layout/images/logos/{{selectedTheme}}/ice.png">
    <input type="text" pInputText placeholder="Search applications..." [(ngModel)]="searchText">

    <div class="contain">
        <div class="app-card" *ngFor="let application of applications | filter: searchText" (click)="navigate(application)">
            <div class="card-back">
                <div class="card-contents">
                    <img src="/assets/layout/images/applications/{{selectedTheme}}/{{application.icon}}" default="/assets/layout/images/applications/{{selectedTheme}}/fallback.svg" />
                </div>
            </div>
            <div>{{application.displayName}}</div>
        </div>
    </div>
    <!-- <div class="footer">This product is currently in beta, we may not have worked out all the kinks yet. Expect cool new features to appear and stability to improve over time.</div> -->
</div>
