import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Application } from 'src/model/Application';
import { CookieService } from 'ngx-cookie-service';
import { DashboardWidgetType } from '../customer/Models/dashboardWidgets';

class TokenClaims {
  user: number;
  email: string;
  useScanner: boolean;
  dashboards: Array<string>;
  logoId = "0";
  logoPath = "";

  constructor() {
    this.user = 0;
    this.email = '';
    this.useScanner = false;
  }
}

@Injectable({
  providedIn: 'root'
})


export class LoginService {

  tokenValues: any = {};
  tokenClaims: TokenClaims;
  isImpersonating: boolean = false;
  
  private applications: Application[];
  
  constructor(
    private http: HttpClient, 
    private router: Router,
    private cookieService: CookieService
  ) { }

  async processLogin(email, password) {
    const user = { username: email, password: password };
    const api = `${environment.url}/login`;

    const result = await this.http.post<any>(api, user).toPromise();
    localStorage["token"] = result.token;
    await this.validateToken();
    return result.token;
  }

  async impersonateUser(userId: number, reason: string) {
    const api = `${environment.url}/user/${userId}/impersonate`;

    const result = await this.http.post<string>(api, {reason}).toPromise();
    localStorage["token"] = result;
    localStorage["isImpersonating"] = true;
    await this.validateToken();
    return result;
  }

 
  async validateToken(redirectToLogin: boolean = true) {
    const token = await this.isLoggedIn();
    if (token) {
      this.isImpersonating = this.checkImpersonation();
      this.tokenValues = jwt_decode(token);
      this.tokenClaims = new TokenClaims();
      this.tokenClaims.email = this.tokenValues.email;
      this.tokenClaims.user = this.tokenValues.user;
      this.tokenClaims.useScanner = this.tokenValues.useScanner;
      this.tokenClaims.dashboards = this.tokenValues.dashboards ? JSON.parse(this.tokenValues.dashboards) : [];
      this.tokenClaims.logoId = this.tokenValues.logoId ? this.tokenValues.logoId : "0";
      this.tokenClaims.logoPath = this.tokenValues.logoPath ? this.tokenValues.logoPath : "";
      await this.getUserApplications();
    } else {
      if (redirectToLogin) {
        this.logout();
      }
    }
  }

  async isLoggedIn(): Promise<string> {
    const token = localStorage.getItem("token");
    if (token) {
      return token; 
    } else {
      return this.checkForCookieLogin();
    }
  }

  private checkImpersonation() {
    return localStorage['isImpersonating'] === 'true';
  }

  getToken(): string {
    const token = localStorage.getItem("token");
    return token;
  }

  async getUserApplications(): Promise<Application[]> {
    if (this.getToken()) {
      if (!this.applications) {
        try {
          this.applications = await this.http.get<Application[]>(`${environment.url}/user/applications`).toPromise();
        } catch (err) {
          if (err.status === 401) {
            this.logout();
            return;
          }
          this.applications = [];
        }
      }
      return this.applications;
    }
  }

  private async checkForCookieLogin(): Promise<string> {
    const cookie = this.cookieService.get('mlapAuth');
    if (cookie) {
      localStorage['token'] = cookie;
      this.cookieService.delete('mlapAuth', undefined, undefined, undefined, "Lax");
      return cookie;
    }
    return undefined;
    
  }

  logout(navigationExtras?: NavigationExtras) {
    this.applications = undefined;
    localStorage.clear();
    this.tokenClaims = new TokenClaims();
    this.isImpersonating = false;
    this.router.navigate(['/login'], navigationExtras);
  }

  async forgotPassword(username: string) {
    const api = `${environment.url}/forgotPassword`;
    await this.http.post<any>(api, {username: username}).toPromise();
  }

  async validatePasswordToken(token: string) {
    return await this.http.post<any>(`${environment.url}/passwordTokenValidate`, {token: token}).toPromise();
  }

  async resetPassword(token: string, password: string) {
    const data = {token: token, password: password};
    await this.http.post<any>(`${environment.url}/setPassword`, data).toPromise();
  }

  async getDashboardConfig(): Promise<DashboardWidgetType[] | undefined> {
    const config = await this.http.get<DashboardWidgetType[] | undefined>(`${environment.connectAPI}/user/icecloud-dashboard-config`).toPromise();
    return config ?? undefined;
  }

  async saveDashboardConfig(config: DashboardWidgetType[]) {
    await this.http.put(`${environment.connectAPI}/user/icecloud-dashboard-config`, config).toPromise();
  }

}
