import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../services/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  username: string;
  password: string;

  showUserDisabled = false;
  userDisabledMessge: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private spinner: NgxSpinnerService
  ) {

  }

  async login() {
    try {
      this.spinner.show();
      const token = await this.loginService.processLogin(this.username, this.password);
      this.showUserDisabled = false;

      this.activatedRoute.queryParams.subscribe(params => {
        if (params["returnUrl"]) {
          this.router.navigate([params["returnUrl"]])
        } else {
          this.router.navigate(['/home']);
        }
      });

    } catch (err) {
      console.log(err);
      if (err.status == 401) {
        this.userDisabledMessge = err.error;
        this.showUserDisabled = true;
      } else {
        this.userDisabledMessge = "Server error: Please contact RTS for support";
        this.showUserDisabled = true;
      }
    }
    finally {
      this.spinner.hide();
    }
  }
}
