<p-toast position="top-right"></p-toast>
<div class="login-body">
    <div id="login-wrapper">
        <div class="logo-container"></div>
        <div id="login-container" style="height: auto;">

            <div class="ui-g ui-fluid" *ngIf="currentState === states.Validating">
                <div class="ui-g-12">
                    <h2>Validating Token</h2>
                </div>
                <div class="ui-g-12">
                    <i class="pi pi-spin pi-spinner" style="font-size: 4em"></i>
                </div>
            </div>

            <div class="ui-g ui-fluid" *ngIf="badToken">
                <div class="ui-g-12">
                    <h2>Invalid token</h2>
                </div>
                <div class="ui-g-12">
                    The submitted token is not valid, click on the url in your email again.
                </div>
            </div>

            <div *ngIf="currentState === states.ExpiredToken">
                <div class="ui-g-12">
                    <h2>Expired Link</h2>
                </div>
                <div style="display: flex; justify-content: center; align-items: center; font-size: large;" class="ui-g-12">
                    <span class="expiry-error">This link has expired or been previously used. You can <a routerLink="/forgotpassword">request another link here</a>.</span>
                </div>
            </div>

            <div *ngIf="currentState === states.ValidToken">
                <form #form="ngForm" (ngSubmit)="setPassword()">
                    <div class="ui-g ui-fluid">
                        <div class="ui-g-12">
                            <span class="title">Change Password</span>
                        </div>
                        <div class="ui-g-12">
                            <span>Please provide and confirm your password below.</span>
                        </div>
                        <div class="ui-g-12">
                            <div>Note that your password must meet all of the following criteria:</div>
                            <ul style="text-align: left;">
                                <li>Be 8 or more characters long</li>
                                <li>Contain a lowercase letter e.g. a, b, c</li>
                                <li>Contain an uppercase letter e.g. R, S, T</li>
                                <li>Contain a number e.g. 0, 1, 5</li>
                                <li>Contain a special character e.g. #, @, %</li>
                            </ul>
                        </div>
                        <div class="p-col-12">
                            <input pInputText  type="password" name="password" autocomplete="off" [(ngModel)]="password" class="ui-inputtext ui-corner-all ui-state-default ui-widget" placeholder="Password" required 
                            pattern="^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\#@$-/:-?{-~!&quot;^_`[\]]).*$" />
                            <div *ngIf="!form.pristine && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.pattern">Password does not meet the above criteria</div>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <input pInputText  type="password" name="confirmPassword" [(ngModel)]="confirmPassword" autocomplete="off" class="ui-inputtext ui-corner-all ui-state-default ui-widget" placeholder="Confirm Password" required />
                            <div class="invalid-feedback" >
                                <div *ngIf="password != confirmPassword">Passwords do not match</div>
                            </div>
                        </div>
                        <div class="ui-g-12">
                            <button pButton [disabled]="form.invalid || form.pristine" type="submit" label="Set Password" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left">
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="ui-g ui-fluid" *ngIf="currentState === states.PasswordSet">
                <div class="ui-g-12">
                    <h2>Password Set</h2>
                </div>
                <div class="ui-g-12">
                    <span class="expiry-error">Your password has now been set. You can now <a routerLink="/login">log in here</a>.</span>
                </div>
            </div>

            <div class="ui-g ui-fluid" *ngIf="currentState === states.Error">
                <div class="ui-g-12">
                    <h2>Error Occured</h2>
                </div>
                <div class="ui-g-12">
                    <span class="expiry-error">Click <a (click)="clearError();">here</a> to try again .</span>
                </div>
            </div>

        </div>
    </div>
    <ngx-spinner></ngx-spinner>
</div>