import { Component, Renderer2 } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoginService } from './services/login.service';
import { ThemeService } from './services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    inputStyle = 'outlined';

    ripple: boolean;

    theme: string;

    constructor(
        private primengConfig: PrimeNGConfig,
        private themeService: ThemeService,
        private loginService: LoginService,
        private renderer: Renderer2
    ) {
        this.themeService.theme.subscribe((t) => {
            this.renderer.removeClass(document.body, this.theme);
            this.theme = t;
            this.renderer.addClass(document.body, this.theme);
            console.log("theme change", t);
        })
    }

    async ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}
