import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BatteryData, LowBatteryDrilldown, MachineAlertDetail } from '../customer/Models/dashboard/batteryData';
import { ChecklistData, ChecklistDetail, ChecklistDrillDown } from '../customer/Models/dashboard/checklistData';
import { FleetOverviewData } from '../customer/Models/dashboard/fleetOverviewData';
import { ImpactData, NoDriverImpactDetail } from '../customer/Models/dashboard/impactData';
import { UtilisationData } from '../customer/Models/dashboard/utilisationData';
import { FleetFilter, FleetFilterData } from '../customer/Models/fleetFilter';
import { OperatorImpacts } from '../customer/Models/operatorImpacts';
import { OperatorImpactsDetail, MachineImpacts, MachineImpactsDetail } from '../customer/Models/machineDetail';
import { MachineUtilisation } from '../customer/Models/machineDetail';
import { DateFilter, FleetAndDateFilter } from '../customer/Models/dateFilter';
import { MachineSpeeding, MachineSpeedingDetail, OperatorSpeeding, OperatorSpeedingDetail, NoOperatorSpeedingDetail } from '../customer/Models/dashboard/speeding';
import { ContractHoursSummary } from '../customer/Models/dashboard/contractHours';
import { ServiceData, ServiceDrillDown } from '../customer/Models/dashboard/serviceData';
import HarshAccelBrakingWidgetData from '../customer/Models/dashboard/HarshAccelBrakingWidgetData';
import { MachineHarsh, MachineHarshDetail, OperatorHarsh } from '../customer/Models/dashboard/harshBrakeAccell';
// import { ContractRunHours } from '../customer/Models/dashboard/drilldown/contractHoursDetail';
// import { ContractRunHoursBelow,ContractRunHoursAbove } from '../customer/Models/dashboard/drilldown/contractHoursDetail';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {

  timeoutMeasure = 3000;

  constructor(private http: HttpClient) { }

  async getUtilisationData(filter?: FleetAndDateFilter): Promise<UtilisationData> {
    return await this.http.post<UtilisationData>(`${environment.fmxDashboardService}getutilizationdata`, filter).toPromise();
  }

  async getContractHoursData(filter?: FleetAndDateFilter): Promise<ContractHoursSummary> {
    return await this.http.post<ContractHoursSummary>(`${environment.fmxDashboardService}getcontracthoursdata`, filter).toPromise();
  }

  async getFleetOverviewData(filter?: FleetFilter): Promise<FleetOverviewData> {
    return await this.http.post<FleetOverviewData>(`${environment.fmxDashboardService}getfleetoverviewdata`, filter).toPromise();
  }

  async getBatteryData(filter?: FleetAndDateFilter): Promise<BatteryData> {
    return await this.http.post<BatteryData>(`${environment.fmxDashboardService}getlowbatterydata`, filter).toPromise();
  }

  async getImpactData(filter?: FleetAndDateFilter): Promise<ImpactData> {
    return await this.http.post<ImpactData>(`${environment.fmxDashboardService}getimpactdata`, filter).toPromise();
  }

  async getDriverImpactData(filter?: FleetAndDateFilter): Promise<OperatorImpacts[]> {
    return await this.http.post<OperatorImpacts[]>(`${environment.fmxDashboardService}drilldown/getoperatorimpactdata`, filter).toPromise();
  }

  async getMachineImpactData(filter?: FleetAndDateFilter): Promise<MachineImpacts[]> {
    return await this.http.post<MachineImpacts[]>(`${environment.fmxDashboardService}drilldown/getmachineimpactdata`, filter).toPromise();
  }

  async getMachineImpactsDetail(id: number, dateFilter: DateFilter): Promise<MachineImpactsDetail> {
    return await this.http.post<MachineImpactsDetail>(`${environment.fmxDashboardService}drilldown/getmachineimpactdata/${id}`, dateFilter).toPromise();
  }

  async getOperatorImpactsDetail(id: number, dateFilter: DateFilter): Promise<OperatorImpactsDetail> {
    return await this.http.post<OperatorImpactsDetail>(`${environment.fmxDashboardService}drilldown/getoperatorimpactdata/${id}`, dateFilter).toPromise();
  }

  async getChecklistData(filter?: FleetAndDateFilter): Promise<ChecklistData> {
    return await this.http.post<ChecklistData>(`${environment.fmxDashboardService}getchecklistdata`, filter).toPromise();
  }

  async getUtilisationBelowDrillDownData(filter?: FleetAndDateFilter): Promise<MachineUtilisation[]> {
    return await this.http.post<MachineUtilisation[]>(`${environment.fmxDashboardService}drilldown/utilisationbelow`, filter).toPromise();
  }

  async getUtilisationAboveDrillDownData(filter?: FleetAndDateFilter): Promise<MachineUtilisation[]> {
    return await this.http.post<MachineUtilisation[]>(`${environment.fmxDashboardService}drilldown/utilisationabove`, filter).toPromise();
  }

  async getLowBatteryDrillDownData(filter?: FleetAndDateFilter): Promise<LowBatteryDrilldown[]> {
    return await this.http.post<LowBatteryDrilldown[]>(`${environment.fmxDashboardService}drilldown/getlowbatterydata`, filter).toPromise();
  }

  async getChecklistDrillDownData(filter?: FleetAndDateFilter): Promise<ChecklistDrillDown[]> {
    return await this.http.post<ChecklistDrillDown[]>(`${environment.fmxDashboardService}drilldown/getchecklistdrilldowndata`, filter).toPromise();
  }

  async getChecklistDetailData(checklistID): Promise<ChecklistDetail> {
    return await this.http.get<ChecklistDetail>(`${environment.fmxDashboardService}drilldown/getchecklistdrilldowndata/${checklistID}`).toPromise();
  }

  async getFleetFilterData(): Promise<FleetFilterData> {
    return await this.http.get<FleetFilterData>(`${environment.fmxDashboardService}getfleetfilterdata`).toPromise();
  }

  async getMachineAlertData(rtuId: number, dateFilter: DateFilter): Promise<MachineAlertDetail[]> {
    return await this.http.post<MachineAlertDetail[]>(`${environment.fmxDashboardService}drilldown/getmachinealertdata/${rtuId}`, {dateFilter: dateFilter}).toPromise();
  }

  async getNoDriverImpactDetail(branchId: number, dateFilter: DateFilter): Promise <NoDriverImpactDetail[]> {
    return await this.http.post<NoDriverImpactDetail[]>(`${environment.fmxDashboardService}drilldown/getnodriverimpactdetail/${branchId}`, {dateFilter: dateFilter}).toPromise();
  }

  async getSpeedingWidgetData(filter?: FleetAndDateFilter): Promise<{x: Date, y: number}[]> {
    return await this.http.post<{x: Date, y: number}[]>(`${environment.fmxDashboardService}getspeedingwidgetdata`, filter).toPromise();
  }

  async getHarshWidgetData(filter?: FleetAndDateFilter): Promise<HarshAccelBrakingWidgetData> {
    return await this.http.post<HarshAccelBrakingWidgetData>(`${environment.fmxDashboardService}getharshwidgetdata`, filter).toPromise();
  }

  async getSpeedingOperatorData(filter?: FleetAndDateFilter): Promise<OperatorSpeeding[]> {
    return await this.http.post<OperatorSpeeding[]>(`${environment.fmxDashboardService}drilldown/getoperatorspeedingdata`, filter).toPromise();
  }

  async getSpeedingOperatorDetail(id: number, dateFilter: DateFilter): Promise<OperatorSpeedingDetail> {
    return await this.http.post<OperatorSpeedingDetail>(`${environment.fmxDashboardService}drilldown/getoperatorspeedingdata/${id}`, dateFilter).toPromise();
  }

  async getSpeedingMachineData(filter?: FleetAndDateFilter): Promise<MachineSpeeding[]> {
    return await this.http.post<MachineSpeeding[]>(`${environment.fmxDashboardService}drilldown/getmachinespeedingdata`, filter).toPromise();
  }

  async getSpeedingMachineDetailData(rtuId: number, dateFilter: DateFilter): Promise<MachineSpeedingDetail> {
    return await this.http.post<MachineSpeedingDetail>(`${environment.fmxDashboardService}drilldown/getmachinespeedingdata/${rtuId}`, dateFilter).toPromise();
  }

  async getHarshMachineDetailData(rtuId: number, dateFilter: DateFilter): Promise<MachineHarshDetail> {
    return await this.http.post<MachineHarshDetail>(`${environment.fmxDashboardService}drilldown/getmachineharshdata/${rtuId}`, dateFilter).toPromise();
  }

  async getHarshOperatorData(filter?: FleetAndDateFilter): Promise<OperatorHarsh[]> {
    return await this.http.post<OperatorHarsh[]>(`${environment.fmxDashboardService}drilldown/getoperatorharshdata`, filter).toPromise();
  }

  async getHarshMachineData(filter?: FleetAndDateFilter): Promise<MachineHarsh[]> {
    return await this.http.post<MachineHarsh[]>(`${environment.fmxDashboardService}drilldown/getmachineharshdata`, filter).toPromise();
  }

  async getSpeedingNoOperatorDetail(branchId: number, dateFilter: DateFilter): Promise<NoOperatorSpeedingDetail> {
    return await this.http.post<NoOperatorSpeedingDetail>(`${environment.fmxDashboardService}drilldown/getbranchspeedingdetail/${branchId}`, dateFilter).toPromise();
  }

  async getContractRunHourDrillDown(filter?: FleetAndDateFilter) {
    return await this.http.post(`${environment.fmxDashboardService}drilldown/getcontractrunhour`, filter).toPromise();
  }

  async getServiceData(filter?: FleetAndDateFilter):Promise<ServiceData> {
    return await this.http.post<ServiceData>(`${environment.fmxDashboardService}getservicedata`, filter).toPromise();
  }

  async getServiceDrilldownData(filter?: FleetFilter):Promise<ServiceDrillDown> {
    return await this.http.post<ServiceDrillDown>(`${environment.fmxDashboardService}drilldown/getservicedata`, filter).toPromise();
  }
}
