<div class="exception-body">
    <div class="exception-wrapper error">
        <img src="assets/layout/images/error.png" alt="omega-layout"/>

        <p class="message-title">Exception Occured!</p>
        <p class="message-summary">Please contact system administrator</p>

        <a href="/">
            <i class="pi pi-home"></i>
            <span>Dashboard</span>
        </a>

        <div class="login-footer">PrimeNG Omega</div>
    </div>
</div>
