import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../services/login.service';
import * as _ from 'lodash';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private loginService: LoginService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const urlToIgnore = ['login', 'forgotPassword', 'setPassword', 'passwordTokenValidate'];
    const spilt = request.url.split('/');
    const url = _.last(spilt);
    
    let path;
    if (urlToIgnore.includes(url)) {
      path = "/" + url;
    }

    if ((request.url.includes(environment.url) || request.url.includes(environment.fmxDashboardService) || request.url.includes(environment.connectAPI) || request.url.includes(environment.roleService)) && request.url.indexOf(path) == -1) {
      request= request.clone({
        setHeaders: {
          userId : this.loginService.tokenClaims.user.toString(),
          "token": this.loginService.getToken()
        }
      })
    }

    return next.handle(request);
  }
}
