<div class="filter-container" [ngClass]="{'open': isOpen}">
    <div class="toolbar">
        <div class="header"></div>
        <div class="link-toolbar">
            <div class="clear-button clickable" (click)="clearFilter();">
                Clear Filter
            </div>
            <div class="apply-button clickable" (click)="applyFilter();">
                Apply Filter
            </div>
        </div>
    </div>
    <div class="layout-container">
        <div class="filter-list x3">
            <div class="section-label">Select which parts of your fleet you wish to display</div>
            <div class="fleet-layout">
                <p-listbox name="selectedCustomers" [multiple]="true" [checkbox]="true" [filter]="true" [options]="customers" [(ngModel)]="selectedCustomers">
                    <p-header><i class="fa fa-building"></i><span class="label">Customers</span></p-header>
                </p-listbox>
                <p-listbox name="selectedBranches" [multiple]="true" [checkbox]="true" [filter]="true" [options]="branches" [(ngModel)]="selectedBranches">
                    <p-header><i class="fa fa-boxes"></i><span class="label">Branches</span></p-header>
                </p-listbox>
                <p-listbox name="selectedBranches" [multiple]="true" [checkbox]="true" [filter]="true" [options]="machines" [(ngModel)]="selectedMachines">
                    <p-header><i class="fa fa-truck-loading"></i><span class="label">Machines</span></p-header>
                </p-listbox>
            </div>
        </div>
        <div class="filter-list">
            <div class="section-label">Models</div>
            <p-listbox name="selectedBranches" [multiple]="true" [checkbox]="true" [filter]="true" [options]="models" [(ngModel)]="selectedModels">
                <p-header><i class="fa fa-boxes"></i><span class="label">Models</span></p-header>
            </p-listbox>
        </div>
    </div>
</div>
