import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';

enum ComponentState { Loading, Validating, ExpiredToken, ValidToken, PasswordSet, Error}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  badToken: boolean;
  token: string;

  password: string;
  confirmPassword: string;

  states = ComponentState;
  currentState: ComponentState = ComponentState.Loading;

  @ViewChild('form') form: NgForm;
  get f() { return this.form.controls; }

  constructor(private route: ActivatedRoute, private router: Router, private loginApi: LoginService, private messageService: MessageService) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'] || '';
    if(this.token === '' || !this.token || this.token === null) {
      this.badToken = true;
    } else {
      this.badToken = false;
      this.validateToken(this.token);
    }
  }

  async validateToken(token) {
    try {
      this.currentState = ComponentState.Validating;
      if (await this.loginApi.validatePasswordToken(token)) {
        this.currentState = ComponentState.ValidToken;
      } else {
        this.currentState = ComponentState.ExpiredToken;
      }
    } catch(err) {
      console.log(err)
      this.messageService.add({ severity: 'error', summary: 'Server Error', detail: 'A server error occured, please contact your administrator.'});
      this.currentState = ComponentState.Error;
    }
  }
  clearError() {
    this.currentState = ComponentState.ValidToken;
  }

  async setPassword() {
    this.currentState = ComponentState.Validating;
    try {
      if (this.password !== this.confirmPassword) {
        this.messageService.add({ severity: 'error', summary: 'Password Mismatch', detail: 'Please ensure your password matches'});
        this.currentState = ComponentState.Error;
      } else {
        await this.loginApi.resetPassword(this.token, this.password);
        this.currentState = ComponentState.PasswordSet;
      }
    } catch (err) {
      if (err.error.includes("Invalid User Token")) {
        this.currentState = ComponentState.ExpiredToken;
      } else {
        this.currentState = ComponentState.Error;
        this.messageService.add({ severity: 'error', summary: 'Server Error', detail: 'A server error occured, please contact your administrator.'});
      }
    }
  }

}
