<div class="exception-body">
    <div class="exception-wrapper access">
        <img src="assets/layout/images/access.png" alt="omega-layout"/>

        <p class="message-title">You do not have permission to access this application</p>
        <p class="message-summary">Please contact your system administrator to request access</p>

        <p-button icon="pi pi-home" routerLink="/home" label="Home"></p-button>

    </div>
</div>
