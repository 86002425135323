import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  username: string;
  hasUserName: boolean;

  constructor(private loginService: LoginService, private messageService: MessageService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.hasUserName = true;
  }

  onChange() {
    if (this.username !== "") {
      this.hasUserName = false;
    }
  }

  async requestReset() {
    this.spinner.show();
    try {
      await this.loginService.forgotPassword(this.username);
      this.spinner.hide();
      this.username = "";
      this.hasUserName = true
      this.messageService.add({ severity: 'info', summary: 'Password Reset', detail: 'Password reset request processed, check your email.' });
    } catch (err) {
      console.log(err)
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Password Reset Error', detail: err });
    }
  }

}
