import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { FleetFilter } from 'src/app/customer/Models/fleetFilter';
import * as _ from 'lodash';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { FleetFilterService } from './fleet-filter.service';

@Component({
  selector: 'app-fleet-filter',
  templateUrl: './fleet-filter.component.html',
  styleUrls: ['./fleet-filter.component.scss']
})
export class FleetFilterComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() filterSet = new EventEmitter<FleetFilter>();
  @Output() filterError = new EventEmitter<string>();

  customers: SelectItem[];
  branches: SelectItem[];
  machines: SelectItem[];
  models: SelectItem[];

  selectedCustomers: number[] = [];
  selectedBranches: number[] = [];
  selectedMachines: number[] = [];
  selectedModels: number[] = [];

  currentFilter: FleetFilter;

  constructor(
    private dashboardDataService: DashboardDataService,
    private fleetFilterService: FleetFilterService
  ) { }

  async ngOnInit(): Promise<void> {
    
    try {
      const filterData = await this.dashboardDataService.getFleetFilterData();  
      this.customers = filterData.customers;
      this.branches = filterData.branches;
      this.machines = filterData.machines;
      this.models = filterData.models;

      //This is done to make the property updates async, to fix an issue where angular tries to update a property during render. 
      //It throws ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.checkForSavedFilter();
      });
    } catch (error) {
      this.filterError.emit('An error occured retrieving filter information.<br/> Please contact your administrator.');
    }
    
    
  }

  ngAfterViewInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'] && changes['isOpen'].currentValue) {
      this.sortLists();
    }
  }
  
  ngOnDestroy(): void {
    this.fleetFilterService.disposeFilter()
  }

  public applyFilter() {
    this.setFilterFromControls();
    this.isOpenChange.emit(false);
  }

  public clearFilter() {
    this.isOpenChange.emit(false);
    this.currentFilter = undefined;
    this.selectedCustomers = [];
    this.selectedBranches = [];
    this.selectedMachines = [];
    this.selectedModels = [];
    localStorage.removeItem('fleet-filter');
    this.emitFilter(this.currentFilter);
  }

  private setFilterFromControls() {
    if (
      this.selectedCustomers.length > 0 ||
      this.selectedBranches.length > 0 ||
      this.selectedMachines.length > 0 ||
      this.selectedModels.length > 0
    ) {
      this.currentFilter = {
        customers: _.clone(this.selectedCustomers),
        branches: _.clone(this.selectedBranches),
        machines: _.clone(this.selectedMachines),
        models: _.clone(this.selectedModels)
      }
      localStorage.setItem('fleet-filter', JSON.stringify(this.currentFilter));
    } else {
      this.currentFilter = undefined;
      localStorage.removeItem('fleet-filter');
    }
    this.emitFilter(this.currentFilter);
  }

  private checkForSavedFilter() {
    const savedFilterString = localStorage.getItem('fleet-filter');
    if (savedFilterString) {
      this.currentFilter = JSON.parse(savedFilterString);
      this.selectedCustomers = _.clone(this.currentFilter.customers);
      this.selectedBranches = _.clone(this.currentFilter.branches);
      this.selectedMachines = _.clone(this.currentFilter.machines);
      this.selectedModels = _.clone(this.currentFilter.models);
    }
    this.emitFilter(this.currentFilter);
  }

  private emitFilter(filter: FleetFilter) {
    this.filterSet.emit(filter);
    this.fleetFilterService.setFilter(filter);
  }

  private sortLists() {
    this.customers = this.selectionSort(this.customers, this.selectedCustomers);
    this.branches = this.selectionSort(this.branches, this.selectedBranches);
    this.machines = this.selectionSort(this.machines, this.selectedMachines);
    this.models = this.selectionSort(this.models, this.selectedModels);
  }

  private selectionSort(unsorted: SelectItem[], selected: number[]) : SelectItem[] {
    const sorted = unsorted.slice().sort((a, b) => {
      const aSelected = _.includes(selected, a.value);
      const bSelected = _.includes(selected, b.value);
      if (aSelected && bSelected) {
        return a.label.localeCompare(b.label, 'en', {numeric: true});
      }
      else if (aSelected) {
        return -1;
      }
      else if (bSelected) {
        return 1;
      } else {
        return a.label.localeCompare(b.label, 'en', {numeric: true});
      }
    });
    return sorted;
  }

}
